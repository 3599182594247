/*
GA
*/

$yellow: #F9D849;

@import '../node_modules/bootstrap/scss/_functions';
@import '../node_modules/bootstrap/scss/_variables';

$theme-colors: (black: $black);
$enable-responsive-font-sizes: true;
$small-font-size: 60%;
$display-line-height: 0.9;
$btn-border-radius: 0.5rem;

@import '../node_modules/bootstrap/scss/bootstrap';

@font-face {
  font-family: Champion;
  font-weight: 800;
  src: url('Champion-HTF-Heavyweight.otf') format('opentype');
}

html,
body {
  height: 100%;
}

body {
  background: url('background.jpg') center center fixed;
  background-size: cover;
  font-family: Champion;
  font-weight: 800;
}

.l-0 {
  left: 0;
}

.r-0 {
  right: 0;
}

.t-0 {
  top: 0;
}

.b-0 {
  bottom: 0;
}

.btn {
  transition: 200ms;
}
.btn:hover {
  transform: scale(1.05);
}

.square:after {
  content: '';
  display: block;
  padding-bottom: 70%;
}

.fade {
  transition: opacity 250ms 250ms, width 0ms 250ms;
  width: 100%;
}
.fade:not(.show) {
  transition: opacity 250ms, width 0ms 250ms;
  width: 0;
  overflow: hidden;
}

@media (min-width: 768px) {
  header {
    position: fixed;
  }

  main {
    min-height: 100vh;
    padding-top: 190px;
    padding-bottom: 30px;
  }

  footer {
    position: fixed;
  }
}